import { useToken } from '@indigo-cloud/common-react';
import MUIAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import User from '../../images/icons/BT_Icons_User_White.svg';
import logoIndigo from '../../images/logo/white.png';
import { drawerWidth } from '../Drawer';
import styles from './AppBar.component.module.scss';

export interface AppBarContainerProperties {
	onSignout: () => void;
}

export interface AppBarState { }

export const appBarDataSelectors = {
	buttonToggleDrawer: 'AppBar-button-menu-toggle',
	buttonToggleMenu: 'AppBar-button-account',
	heading: 'AppBar-button-heading',
	menu: 'AppBar-menu',
	menuButtonCopyToken: 'AppBar-menu-button-copyToken',
	menuButtonLogout: 'AppBar-menu-button-logout'
};

const useStyles = makeStyles<Theme>((theme) => ({
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			duration: theme.transitions.duration.leavingScreen,
			easing: theme.transitions.easing.sharp
		})
	},

	appBarShift: {
		marginRight: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			duration: theme.transitions.duration.enteringScreen,
			easing: theme.transitions.easing.easeOut
		}),
		width: `calc(100% - ${drawerWidth}px)`
	}
}));

export const AppBar: React.FC<AppBarContainerProperties> = ({ onSignout }) => {
	const [token, retriveToken] = useToken();

	const classes = useStyles();

	const location = useLocation();
	const appName = useMemo(() => {
		if (location?.pathname.includes('suota')) {
			return 'SUOTA';
		}
		if (location?.pathname.includes('usp-controller')) {
			return 'USP CONTROLLER';
		}
		if (location?.pathname.includes('admin')) {
			return 'ADMIN';
		}

		return '';

	}, [location]);

	/* eslint-disable-next-line unicorn/no-null */
	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

	const isMenuOpen = Boolean(anchorElement);

	const onClickMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setAnchorElement(event.currentTarget);
	}, []);

	const onMenuClose = useCallback(() => {
		/* eslint-disable-next-line unicorn/no-null */
		setAnchorElement(null);
	}, []);

	const onClickCopyButton = useCallback(() => {
		if (token !== undefined) {
			navigator.clipboard.writeText(token);
		} else {
			retriveToken();
		}
	}, [token]);

	useEffect(() => {
		if (document.hasFocus()) {
			onClickCopyButton();
		}
	}, []);

	const onClickLogout = useCallback(() => {
		onSignout();
		onMenuClose();
	}, [onSignout]);


	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={anchorElement}
			anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
			id={menuId}
			keepMounted
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			open={isMenuOpen}
			onClose={onMenuClose}
			data-cy={appBarDataSelectors.menu}>
			<MenuItem onClick={onClickCopyButton} data-cy={appBarDataSelectors.menuButtonCopyToken}>
				Copy Token To Clipboard
			</MenuItem>
			<MenuItem onClick={onClickLogout} data-cy={appBarDataSelectors.menuButtonLogout}>
				Logout
			</MenuItem>
		</Menu>
	);

	return (
		<>
			<div data-cy="AppBar" className={clsx(classes.appBar, styles.container)}>
				<MUIAppBar position="static" id={styles.appBar} >
					<Toolbar>
						<img src={logoIndigo} id={styles.logo} />
						<Typography className={styles.title} variant="h6" noWrap data-cy={appBarDataSelectors.heading} >
							{appName}
						</Typography>
						<div className="grow" />
						<Tooltip title="Account">
							<IconButton
								edge="end"
								aria-label="account of current user"
								aria-controls={menuId}
								aria-haspopup="true"
								onClick={onClickMenuOpen}
								data-cy={appBarDataSelectors.buttonToggleMenu}
								size="large">
								<img src={User} width={32} height={32} />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</MUIAppBar>
				{renderMenu}
			</div>
		</>
	);
};

AppBar.propTypes = {
	onSignout: PropTypes.func.isRequired
};
